import HomeLayout from "src/Vistas/LayOuts/HomeLayout.vue";
import VistasAuthLayout from "src/Vistas/LayOuts/AuthLayout.vue";
// GeneralViews
// import PaginaNoEncontrada from "src/Vistas/Main/PaginaNoEncontrada.vue";

let authVistas = {
  path: "/",
  component: VistasAuthLayout,
  name: "Authentication",
  children: [
  ]
}
// GeneralViews
const routes = [
  {
    path: "/home",
    name: "Home",
    component: () =>
    import(/* webpackChunkName: "default" */ "src/pages/Ustorage/index.vue"),
  },
  {
    path: "/",
    name: "Default",
    component: () =>
      import(/* webpackChunkName: "default" */ "src/Vistas/Main/Default.vue"),
  },
  // authVistas,
  // Agregar código para mostrar vacio
  // { path: "*", name: "Página no encontrada", component: PaginaNoEncontrada },
];

export default routes;
